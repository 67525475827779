import Phaser from 'phaser';

class Sprite extends Phaser.GameObjects.Sprite {
	watchEntityProperties(properties) {
		properties.forEach((property) => {
			this.watchEntityProperty(property);
		});
	}
	watchEntityProperty(property) {
		let substitute = `__${property}`;
		this.entity[substitute] = this.entity[property];
		Object.defineProperty(this.entity, property, {
			get: () => {
				return this.entity[substitute];
			},
			set: (value) => {
				this[property] = this.entity[substitute] = value;
			}
		});

		if(this.entity[substitute]) {
			this[property] = this.entity[substitute];
		}
	}
}

export default Sprite;