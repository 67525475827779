<template>
	<v-card class="bottom-panel">
		<selected-planet :selected-entity="selectedEntity" v-if="isSelectedPlanet" />
		<selected-sector :selected-entity="selectedEntity" v-if="isSelectedSystem" />
		<selected-fleet :selected-entity="selectedEntity" v-if="isSelectedFleet" />
		<div class="right-content" />
		<turn-panel :scene="scene" v-if="scene" />
	</v-card>
</template>

<script>
import SelectedPlanet from './SelectedPlanet';
import SelectedSector from './SelectedSector';
import SelectedFleet from './SelectedFleet';
import TurnPanel from './TurnPanel';
import Planet from '@/game/entities/planet';
import SolarSystem from '@/game/entities/solar-system';
import Fleet from '@/game/entities/fleet';

export default {
	props: {
		selectedEntity: null,
		scene: null
	},
	computed: {
		isSelectedPlanet() {
			return this.selectedEntity instanceof Planet;
		},
		isSelectedSystem() {
			return this.selectedEntity instanceof SolarSystem;
		},
		isSelectedFleet() {
			return this.selectedEntity instanceof Fleet;
		}
	},
	components: {
		SelectedPlanet,
		SelectedSector,
		SelectedFleet,
		TurnPanel
	}
};
</script>

<style scoped>
	.bottom-panel {
		background-color: transparent;

		display: flex;
		flex-direction: row;
	}

	.right-content {
		flex-grow: 1;
	}
</style>