import Phaser from 'phaser';
import SaveService from '@/game/services/save-service';
import BootScene from './scenes/boot-scene';
import TitleScene from './scenes/title-scene';
import GalaxyScene from './scenes/galaxy-scene';

// TODO: Make load config async
// import solConfig from '@/game/data/systems/sol.json';

function launch() {
	let container = document.getElementById('game-container');

	let game = new Phaser.Game({
		type: Phaser.AUTO,
		physics: {
			default: 'arcade',
			arcade: {
				debug: false,
				debugShowBody: true,
				debugShowStaticBody: true,
				debugShowVelocity: true
			}
		},
		dom: {
			createContainer: true
		},
		scale: {
			mode: Phaser.Scale.RESIZE,
			parent: 'game-container',
			width: container.scrollWidth,
			height: container.scrollHeight
		},
		scene: [BootScene, TitleScene, GalaxyScene]
	});

	game.initFirebase = function(firebase) {
		this.firebase = firebase;

		this.user = this.firebase.auth().currentUser;
		this.firebase.auth().onAuthStateChanged((user) => {
			this.user = user;
			this.saveService.setUser(user);
		});

		SaveService.firebase = firebase;
	};
	game.initSaveService = function() {
		this.saveService = new SaveService();
		this.saveService.firebase = this.firebase;
	};

	return game;
}

export default launch;
export { launch };