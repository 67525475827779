import Vector2 from 'phaser/src/math/Vector2';
import Command from '../command';

const MOVEMENT_SPEED = 120;
const DEFAULT_GET_WITHIN = 2;

class MoveToCommand extends Command {
	constructor(entity, x, y) {
		super(entity);

		this.startingPosition = new Vector2(this.entity.x, this.entity.y);
		this.destination = new Vector2(x, y);

		this.movementLeft = this.destination.subtract(this.startingPosition);
		this.movementVector = this.movementLeft.clone().normalize();
		this.entity.rotation = this.movementVector.angle() + Math.PI / 2;
		this.getWithin = DEFAULT_GET_WITHIN;
	}

	update(totalTime, elapsedTime) {
		let elapsedSeconds = elapsedTime / 1000;
		let totalMovement = elapsedSeconds * MOVEMENT_SPEED;

		let dx = totalMovement * this.movementVector.x;
		let dy = totalMovement * this.movementVector.y;

		this.entity.x += dx;
		this.entity.y += dy;
		this.movementLeft.x -= dx;
		this.movementLeft.y -= dy;

		let vector = new Vector2(dx, dy);
		let length = vector.length();
		this.entity.movementLeft = Math.max(0, this.entity.movementLeft - length);
	}

	isDone() {
		return this.entity.movementLeft <= 0 || this.isSucceeded();
	}

	isSucceeded() {
		return this.movementLeft.length() < this.getWithin;
	}
}

export default MoveToCommand;