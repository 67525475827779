export default {
	type: 'Light Frigate',
	class: 'ship',
	sprite: 'ships/cruiser',
	scale: 0.4,
	hull: 100,
	attack: 4,
	guns: 5,
	shields: 2,
	movement: 300,
	wingSize: 2,
	cost: 200,
	upkeep: 10,
	turns: 1,
	size: 5
};