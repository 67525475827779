import Entity from './entity';
import EntityFactory from './entity-factory';
import Fleet from './fleet';

class Planet extends Entity {
	constructor() {
		super();
		
		this.class = 'planet';
		this.solarSystem = null;
		this.population = 0;
		this.buildings = [];
		this.dockedFleet = null;
		this.shipBuildingQueue = [];
	}

	get income() {
		return (this.colony?.level ?? 0) * this.population;
	}

	get colony() {
		return this.buildings.find((building) => {
			return building.name === 'Colony';
		});
	}
	get orbitalPlatforms() {
		let colony = this.colony;
		if(colony) {
			return colony.currentLevel.orbitalPlatforms;
		} else {
			return null;
		}
	}
	get defenses() {
		let defenses = [...this.orbitalPlatforms];

		if(this.dockedFleet) {
			defenses.push(...this.dockedFleet.ships);
		}

		return defenses;
	}
	get defensesQuantity() {
		return this.defenses.reduce((total, defense) => {
			return total + Math.ceil(defense.quantity);
		}, 0);
	}

	loadBuildings(buildings) {
		buildings.forEach((buildingConfig) => {
			if(!buildingConfig.class) {
				buildingConfig.class = 'building';
			}

			let building = EntityFactory.getEntity(buildingConfig);
			this.buildings.push(building);
			building.planet = this;
		});
	}
	loadDockedFleet(fleetConfig) {
		if(!fleetConfig.class) {
			fleetConfig.class = 'fleet';
		}

		let fleet = EntityFactory.getEntity(fleetConfig);
		this.dockFleet(fleet);
		fleet.dockedWith = this;
		fleet.visible = false;
		fleet.x = this.x;
		fleet.y = this.y;
		this.owner.ownedFleets.push(fleet);
	}
	loadOwner(owner) {
		super.loadOwner(owner);

		if(owner) {
			this.owner.ownedPlanets.push(this);
		}
	}

	saveFull() {
		return {
			population: this.population,
			buildings: this.buildings.map(building => building.save()),
			// dockedFleet: this.dockedFleet,
			shipBuildingQueue: this.shipBuildingQueue.map(ship => ship.save()),
			x: this.x - (this.solarSystem?.x ?? 0),
			y: this.y - (this.solarSystem?.y ?? 0),
			scale: this.scale,
			sprite: this.sprite,
			name: this.name,
			owner: this.owner?.id
		};
	}

	dockFleet(fleet) {
		this.dockedFleet = fleet;
	}
	undockFleet() {
		this.dockedFleet = null;
	}

	isUpgradingBuilding() {
		return this.buildings.filter((building) => {
			return building.isUpgrading();
		}).length > 0;
	}

	getShipyard() {
		return this.buildings.filter((building) => {
			return building.type === 'shipyard';
		})[0];
	}
	canBuildShips() {
		return !!this.getShipyard() && this.isMainPlayer();
	}
	canBuildShip(ship) {
		let player = this.owner;
		return player.money >= ship.cost;
	}
	isBuildingShip() {
		return this.shipBuildingQueue.length > 0;
	}
	getShipBuilding() {
		return this.shipBuildingQueue[0];
	}
	getShipsCanBuild() {
		return this.getShipyard().currentLevel.ships.map((type) => {
			return EntityFactory.getEntity({
				type: type
			});
		});
	}
	addShipToBuildQueue(ship) {
		ship.buildTurnsLeft = ship.turns;
		this.shipBuildingQueue.push(ship);

		let player = this.owner;
		player.money -= ship.cost;
	}
	cancelShipBuildingQueue() {
		let player = this.owner;
		this.shipBuildingQueue.forEach((ship) => {
			player.money += ship.cost;
		});

		this.shipBuildingQueue.splice(0, this.shipBuildingQueue.length);
	}

	changeOwner(newOwner) {
		if(this.owner) {
			this.owner.removePlanet(this);
		}

		newOwner.addPlanet(this);
		this.owner = newOwner;
	}

	finishTurn() {
		this.buildings.forEach((building) => {
			building.finishTurn();
		});

		let shipBuilding = this.getShipBuilding();
		if(shipBuilding) {
			shipBuilding.buildTurnsLeft--;

			if(shipBuilding.buildTurnsLeft <= 0) {
				let fleet = new Fleet();
				fleet.x = this.x;
				fleet.y = this.y;
				fleet.ships.push(shipBuilding);
				fleet.movementLeft = fleet.movement;
				fleet.load({
					owner: this.owner.id
				});
				this.solarSystem.universe.addFleet(fleet);

				this.shipBuildingQueue.splice(0, 1);
			}
		}
	}
}

export default Planet;