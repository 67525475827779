<template>
	<component v-bind:is="currentRouteComponent" :scene="scene" :router="this" />
</template>

<script>
export default {
	props: {
		routes: null,
		scene: null
	},
	data() {
		return {
			currentRoute: 'default',
			history: []
		};
	},
	computed: {
		currentRouteComponent() {
			return this.routes[this.currentRoute];
		}
	},
	methods: {
		goTo(route) {
			this.history.push(this.currentRoute);
			this.currentRoute = route;
		},
		back() {
			if(this.history.length === 0) {
				return;
			}

			let route = this.history.pop();
			this.currentRoute = route;
		},
		startOver() {
			this.history = [];
			this.currentRoute = 'default';
		}
	}
};
</script>