<template>
	<v-card class="player-summary">
		<v-list dense>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-list-item v-on="on">
						<v-icon>attach_money</v-icon> {{ player.money }}
						<span class="green--text margin-left">(+ {{ player.income - player.upkeep }})</span>
					</v-list-item>
				</template>
				<div>
					<div class="money-tooltip-item"><div>Income:</div> <v-spacer class="money-tooltip-spacer"/><div>{{ player.income }}</div></div>
					<div class="money-tooltip-item"><div>Upkeep:</div> <v-spacer class="money-tooltip-spacer"/><div>{{ player.upkeep }}</div></div>
					<div class="money-tooltip-item"><div>Total:</div> <v-spacer class="money-tooltip-spacer"/><div>{{ player.income - player.upkeep }}</div></div>
				</div>
			</v-tooltip>
		</v-list>
	</v-card>
</template>

<script>
export default {
	props: {
		player: null
	}
};
</script>

<style scoped>
	.player-summary {
		flex-grow: 0;
	}

	.margin-left {
		margin-left: 0.25em;
	}

	.money-tooltip-item {
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
	}
	.money-tooltip-spacer {
		min-width: 0.5em;
	}
</style>