import Command from './command';

class CallbackCommand extends Command {
	constructor(entity, callback) {
		super(entity);

		this.callback = callback;
		this.callbackCalled = false;
	}

	// eslint-disable-next-line	
	update(totalTime, elapsedTime) {
		this.callback();
		this.callbackCalled = true;
	}
	isDone() {
		return this.callbackCalled;
	}
}

export default CallbackCommand;