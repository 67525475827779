<template>
	<div class="sector-selector">
		<v-card>
			<v-list dense>
				<v-subheader class="title">{{ selectedEntity.name }}</v-subheader>

				<v-list-item>
					Income: {{ selectedEntity.income }}
				</v-list-item>
			</v-list>
		</v-card>

		<v-card v-for="planet in planets" :key="planet.name">
			<v-list dense>
				<v-subheader class="title">{{ planet.name }}</v-subheader>

				<template v-if="planet.colony">
					<v-list-item>Income: {{ planet.income }}</v-list-item>
				</template>
				<template v-else>
					<v-list-item>Empty</v-list-item>
				</template>
			</v-list>
		</v-card>
	</div>
</template>

<script>
export default {
	props: {
		selectedEntity: null
	},
	computed: {
		planets() {
			return this.selectedEntity.planets;
		},
		buildings() {
			return this.selectedEntity.buildings;
		}
	}
};
</script>

<style scoped>
	.sector-selector {
		display: flex;
		flex-direction: row;
	}
</style>