<template>
	<v-dialog v-model="visible" content-class="attack-fleet-dialog" >
		<v-card>
			<v-card-title class="headline">Attack</v-card-title>

			<div class="attack-fleet-dialog-fleet-splitter">
				<v-list dense v-if="myFleet">
					<v-subheader class="title">Your fleet</v-subheader>

					<v-list-item v-for="ship in myFleet.ships" :key="ship.id">x{{ Math.ceil(ship.quantity) }} {{ ship.type }}</v-list-item>
				</v-list>

				<div style="min-width: 2em" />

				<v-list dense v-if="enemyPlanet">
					<v-subheader class="title">Enemy defenses</v-subheader>

					<v-list-item v-for="defense in enemyPlanet.defenses" :key="defense.id">x{{ Math.ceil(defense.quantity) }} {{ defense.type || defense.name }}</v-list-item>
				</v-list>
			</div>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="orange darken-1" text @click="automateAttack">
					Automate
				</v-btn>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn color="green darken-1" text v-on="on">
							Lead Attack
						</v-btn>
					</template>
					<span>Coming soon...</span>
				</v-tooltip>
				
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import PlanetBattleResultsDialog from './PlanetBattleResultsDialog';
import { calculatePlanetBattle } from '@/game/utils/battles';

export default {
	data() {
		return {
			visible: true,
			myFleet: null,
			enemyPlanet: null,
			scene: null
		};
	},
	methods: {
		automateAttack() {
			let results = calculatePlanetBattle(this.myFleet, this.enemyPlanet);
			
			if(this.myFleet.isDestroyed()) {
				this.myFleet.explode();
			}

			if(this.enemyPlanet.dockedFleet && this.enemyPlanet.dockedFleet.isDestroyed()) {
				this.enemyPlanet.dockedFleet.destroy();
			}

			if(this.enemyPlanet.defensesQuantity === 0) {
				this.enemyPlanet.changeOwner(this.myFleet.owner);
			}
			
			this.scene.setDialog(PlanetBattleResultsDialog, {
				myFleet: this.myFleet,
				enemyPlanet: this.enemyPlanet,
				results: results
			});
		}
	}
};
</script>

<style>
	.attack-fleet-dialog {
		display: flex;
		width: initial;
	}

	.attack-fleet-dialog-fleet-splitter {
		display: flex;
		flex-direction: row;
	}
</style>