import Sprite from './sprite';

class FleetSprite extends Sprite {
	constructor(scene, entity) {
		super(scene, entity.x, entity.y, entity.getFlagShip().sprite);
		this.scene = scene;
		this.entity = entity;
		this.entity.sprite = this;
		
		this.init();
		this.initConfig(entity.config);
	}
	
	init() {
		this.scene.add.existing(this);
		this.scene.fleets.add(this);

		this.setInteractive();
		this.on('pointerup', (pointer) => {
			if(pointer.leftButtonReleased()) {
				this.scene.setSelectedSprite(this);
			}
		});
		
		this.watchEntityProperties(['x', 'y', 'rotation', 'visible']);
	}
	initConfig() {
		this.scaleX = this.scaleY = this.entity.getFlagShip().scale ?? 1;

		if(this.entity.visible === false) {
			this.visible = false;
		}
	}

	setSelected(selected) {
		if(selected === this.selected) {
			return;
		}

		this.selected = selected;

		if(selected) {
			this.setPipeline('outline');

			if(!this.selectionGraphics) {
				this.selectionGraphics = this.scene.add.graphics();
			}
			this.updateSelection();
		} else {
			this.resetPipeline();

			if(this.selectionGraphics) {
				this.selectionGraphics.clear();
			}
		}
	}
	updateSelection() {
		this.selectionGraphics.clear();

		if(this.entity.movementLeft) {
			if(this.entity.isMainPlayer()) {
				this.selectionGraphics.lineStyle(1, 0xFFFFFF, 1);
			} else if(this.entity.isEnemyPlayer()) {
				this.selectionGraphics.lineStyle(1, 0xFF0000, 1);
			} else if(this.entity.isAllyPlayer()) {
				this.selectionGraphics.lineStyle(1, 0x00FF00, 1);
			}
			this.movementCircle = this.selectionGraphics.strokeCircle(this.x, this.y, this.entity.movementLeft);
		}
	}

	update(totalTime, elapsedTime) {
		this.entity.updateDisplay(totalTime, elapsedTime);
	}

	setDestination(currentlyOver, x, y, onComplete) {
		let clickedOnEntities = currentlyOver.map((sprite) => sprite.entity).filter((entity) => !!entity);
		return this.entity.setDestination(clickedOnEntities, x, y, onComplete);
	}
	
	explode() {
		// Place the explosion on the screen, and play the animation.
		let explosion = this.scene.explosions.get().setActive(true);
		explosion.setOrigin(0.5, 0.5);
		explosion.x = this.x;
		explosion.y = this.y;
		explosion.play('explode');
		explosion.on('animationcomplete', () => explosion.destroy());
	}
	destroy() {
		if(this.scene) {
			this.scene.unselectIfSelected(this);

			super.destroy();
		}
	}
}

export default FleetSprite;