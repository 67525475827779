import { Scene, Input } from 'phaser';
import GalaxyScreen from '@/client/components/galaxy/GalaxyScreen';
import PauseMenu from '@/client/components/galaxy/dialogs/PauseMenu';
import AttackFleetDialog from '@/client/components/galaxy/dialogs/AttackFleetDialog';
import AttackPlanetDialog from '@/client/components/galaxy/dialogs/AttackPlanetDialog';

import SpriteFactory from '../sprites/sprite-factory';
import OutlinePipeline from '../shaders/outline-pipeline';
import FleetSprite from '../sprites/fleet';
import PlanetSprite from '../sprites/planet';
import Universe from '@/game/entities/universe';

import defaultConfig from '@/game/data/maps/default';

export default class GalaxyScene extends Scene {
	constructor() {
		super({ key: 'GalaxyScene' });

		this.isZoomedOut = false;
		this.universe = new Universe();
		this.config = defaultConfig;
	}

	get loading() {
		return this.universe.loading;
	}

	preload() {
		this.game.hud.component = GalaxyScreen;
		this.game.saveService.universe = this.universe;
		this.initEntities();
	}
	initEntities() {
		this.universe.load(this.config);
	}
    
	create() {
		this.solarSystems = this.physics.add.group();
		this.planets = this.physics.add.group();
		this.fleets = this.physics.add.group();
		this.game.hud.$nextTick(() => {
			let component = this.game.hud.$refs.component;
			component.camera = this.cameras.main;
			component.scene = this;
			component.player = this.universe.mainPlayer;
			this.component = component;
		});
		this.drawGraphics = this.add.graphics();
		this.drawGraphics.lineStyle(4, 0xFFFFFF, 0.6);

		this.universe.solarSystems.forEach((solarSystem) => {
			SpriteFactory.addSprite(this, solarSystem);
		});

		this.universe.fleets.forEach((fleet) => {
			SpriteFactory.addSprite(this, fleet);
		});

		this.universe.on('addFleet', (fleet) => {
			SpriteFactory.addSprite(this, fleet);
		});
		this.universe.on('removeFleet', (fleet) => {
			if(fleet.sprite) {
				fleet.sprite.destroy();
			}
		});
		this.universe.on('addSolarSystem', (solarSystem) => {
			SpriteFactory.addSprite(this, solarSystem);
		});
		this.universe.on('removeSolarSystem', (solarSystem) => {
			if(solarSystem.sprite) {
				solarSystem.sprite.destroy();
			}
		});

		this.anims.create({
			key: 'explode',
			frames: this.anims.generateFrameNumbers('explode-sheet', {
				start: 0,
				end: 15
			}),
			frameRate: 20,
			repeat: false,
			hideOnComplete: true
		});
		this.explosions = this.add.group({
			defaultKey: 'explode',
			maxSize: 30
		});

		this.keys = this.initKeyBindings();
		this.initMouseBindings();
		this.initZoom();
		this.initShaders();
	}

	update(totalTime, elapsedTime) {
		this.handlePlayerInput(elapsedTime);
		
		if(this.selectedSprite && this.selectedSprite.updateSelection) {
			this.selectedSprite.updateSelection();
		}

		this.fleets.children.entries.forEach((fleet) => {
			fleet.update(totalTime, elapsedTime);
		});
	}

	canPlayerInput() {
		return true;
	}

	handlePlayerInput(elapsedTime) {
		if(!this.canPlayerInput()) {
			return;
		}

		let cameraSpeed = 0.8;
		if(this.keys.moveUp.isDown) {
			this.cameras.main.scrollY -= cameraSpeed * elapsedTime / this.cameras.main.zoom;
		}
		if(this.keys.moveDown.isDown) {
			this.cameras.main.scrollY += cameraSpeed * elapsedTime / this.cameras.main.zoom;
		}

		if(this.keys.moveLeft.isDown) {
			this.cameras.main.scrollX -= cameraSpeed * elapsedTime / this.cameras.main.zoom;
		}
		if(this.keys.moveRight.isDown) {
			this.cameras.main.scrollX += cameraSpeed * elapsedTime / this.cameras.main.zoom;
		}
	}

	initKeyBindings() {
		var keys = {};

		keys.moveUp = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.W);
		keys.moveDown = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.S);
		keys.moveLeft = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.A);
		keys.moveRight = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.D);
		
		keys.shake = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.SPACE);
		keys.shake.on('down', () => {
			this.cameras.main.shake(400);
		});
		keys.esc = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.ESC);
		keys.esc.on('down', () => {
			// TODO: This doesn't actually work since esc appears to close to before this code even though I don't see it anywhere in my code or in Vuetify docs
			if(this.component && this.component.$refs.dialog && this.component.$refs.dialog.visible) {
				this.component.$refs.dialog.visible = false;
			} else {
				this.setDialog(PauseMenu, {
					universe: this.universe
				});
			}
		});

		return keys;
	}
	initMouseBindings() {
		this.input.mouse.disableContextMenu();

		this.input.on('pointerdown', (pointer, currentlyOver) => {
			if(pointer.leftButtonDown() && this.selectedSprite && !currentlyOver.includes(this.selectedSprite)) {
				this.setSelectedSprite(null);
			} else if(pointer.rightButtonDown() && this.selectedSprite && this.selectedSprite instanceof FleetSprite && !currentlyOver.includes(this.selectedSprite) && this.selectedSprite.entity.owner === this.universe.mainPlayer) {
				let enemyFleet = currentlyOver.filter((sprite) => {
					return sprite instanceof FleetSprite && sprite.entity.isEnemyPlayer();
				})[0];
				let enemyPlanet = currentlyOver.filter((sprite) => {
					return sprite instanceof PlanetSprite && sprite.entity.isEnemyPlayer();
				})[0];

				let status;
				if(enemyFleet) {
					status = this.selectedSprite.setDestination(currentlyOver, pointer.worldX, pointer.worldY, () => {
						this.setDialog(AttackFleetDialog, {
							myFleet: this.selectedSprite.entity,
							enemyFleet: enemyFleet.entity
						});
					});
				} else if(enemyPlanet) {
					status = this.selectedSprite.setDestination(currentlyOver, pointer.worldX, pointer.worldY, () => {
						this.setDialog(AttackPlanetDialog, {
							myFleet: this.selectedSprite.entity,
							enemyPlanet: enemyPlanet.entity
						});
					});
				} else {
					status = this.selectedSprite.setDestination(currentlyOver, pointer.worldX, pointer.worldY);
				}

				if(status.errorMessage) {
					this.setErrorMessage(status.errorMessage);
				}
			}
		});
	}
	setDialog(component, props = {}) {
		if(this.component.dialog && this.component.dialog == component) {
			let dialog = this.component.$refs.dialog;
			dialog.visible = true;
			for(var prop in props) {
				dialog[prop] = props[prop];
			}
		} else {
			this.component.dialog = component;
			this.component.$nextTick(() => {
				let dialog = this.component.$refs.dialog;
				for(var prop in props) {
					dialog[prop] = props[prop];
				}

				dialog.scene = this;
			});
		}
	}
	setErrorMessage(message) {
		this.component.errorMessage = message;
	}

	initZoom() {
		const ZOOM_LEVELS = [0.025, 0.05, 0.1, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.5, 2.0];

		let zoomListener;
		let zoomIndex = ZOOM_LEVELS.indexOf(1);
		window.addEventListener('wheel', zoomListener = (event) => {
			let camera = this.cameras.main;
			if(event.deltaY > 0) {
				zoomIndex = Math.max(0, zoomIndex - 1);
			} else {
				zoomIndex = Math.min(ZOOM_LEVELS.length - 1, zoomIndex + 1);
			}

			let zoomTo = ZOOM_LEVELS[zoomIndex];
			// console.log(`destinationZoom: ${zoomTo}`);
			camera.zoomTo(zoomTo, 500, 'Linear', true);
			this.onZoom(zoomTo);
		});

		this.events.on('destroy', () => {
			window.removeEventListener('wheel', zoomListener);
		});
	}
	onZoom(zoomTo) {
		if(zoomTo >= 0.4) {
			if(this.isZoomedOut) {
				this.solarSystems.children.entries.forEach((solarSystem) => {
					solarSystem.showSystemOverview();
				});

				this.isZoomedOut = false;
			}
		} else if(zoomTo <= 0.025) {
			this.solarSystems.children.entries.forEach((solarSystem) => {
				solarSystem.updateSystemSize(12);
			});
		} else if(zoomTo <= 0.05) {
			this.solarSystems.children.entries.forEach((solarSystem) => {
				solarSystem.updateSystemSize(8);
			});
		} else if(zoomTo <= 0.1) {
			this.solarSystems.children.entries.forEach((solarSystem) => {
				solarSystem.updateSystemSize(4);
			});
		} else {
			if(!this.isZoomedOut) {
				this.solarSystems.children.entries.forEach((solarSystem) => {
					solarSystem.hideSystemOverview();
				});

				this.isZoomedOut = true;
			} else {
				this.solarSystems.children.entries.forEach((solarSystem) => {
					solarSystem.updateSystemSize(2);
				});
			}
		}
	}

	initShaders() {
		this.game.renderer.addPipeline('outline', new OutlinePipeline(this.game));
	}

	setSelectedSprite(sprite) {
		if(sprite === this.selectedSprite) {
			return;
		}

		if(this.selectedSprite) {
			this.selectedSprite.setSelected(false);
		}

		this.selectedSprite = sprite;

		if(sprite) {
			sprite.setSelected(true);
			this.component.selectedEntity = sprite.entity;
		} else {
			this.component.selectedEntity = null;
		}
	}
	unselectIfSelected(sprite) {
		if(sprite === this.selectedSprite) {
			this.setSelectedSprite(null);
		}
	}
	endTurn() {
		this.universe.finishTurn();
	}
}
