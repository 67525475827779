import calculateRoundOfBattle from './battle-round';
import simplifyShips from './simplify-ships';

export default function calculateFleetBattle(attackingFleet, defendingFleet) {
	let attackingShips = attackingFleet.ships;
	let defendingShips = defendingFleet.ships;
	let lostAttackingShips = simplifyShips(attackingShips);
	let lostDefendingShips = simplifyShips(defendingShips);

	while(attackingFleet.shipsQuantity > 0 && defendingFleet.shipsQuantity > 0) {
		calculateRoundOfBattle(attackingShips, defendingShips);
	}

	lostAttackingShips.forEach((ship, index) => {
		ship.quantity = ship.quantity - attackingShips[index].quantity;
	});
	lostDefendingShips.forEach((ship, index) => {
		ship.quantity = ship.quantity - defendingShips[index].quantity;
	});

	attackingFleet.ships = attackingFleet.ships.filter((ship) => {
		return ship.quantity > 0;
	});
	defendingFleet.ships = defendingFleet.ships.filter((ship) => {
		return ship.quantity > 0;
	});

	return {
		attackWon: attackingFleet.shipsQuantity > 0,
		lostAttackingShips,
		lostDefendingShips
	};
}