<template>
	<div class="sector-ship">
		<v-card class="fleet-card">
			<v-list dense>
				<v-subheader class="title" :class="fleetClass">{{ fleetName }}</v-subheader>

				<v-list-item dense>Admiral <v-spacer style="min-width: 1em"/> {{ selectedEntity.admiral.name }}</v-list-item>
				<v-list-item>Ships <v-spacer/> {{ selectedEntity.shipsQuantity }}</v-list-item>
				<v-list-item>Size <v-spacer/> {{ selectedEntity.shipsSize }} / {{ maxFleetSize }}</v-list-item>
				<v-list-item v-if="owned">Upkeep <v-spacer/> {{ selectedEntity.upkeep }}</v-list-item>
			</v-list>
		</v-card>
		<v-card v-for="ship in selectedEntity.ships" :key="ship.id">
			<v-list dense>
				<v-subheader class="title">x{{ Math.ceil(ship.quantity) }} {{ ship.type }}</v-subheader>

				<v-list-item>Hull <v-spacer/> {{ ship.hull }}</v-list-item>
				<v-list-item>Attack <v-spacer/> {{ ship.guns }} x {{ ship.attack }}</v-list-item>
				<v-list-item>Shields <v-spacer/> {{ ship.shields }}</v-list-item>
				<v-list-item>Size <v-spacer/> {{ ship.size }} ({{ Math.round(ship.size * ship.quantity) }})</v-list-item>
			</v-list>
		</v-card>
	</div>
</template>

<script>
import Fleet from '@/game/entities/fleet';

export default {
	props: {
		selectedEntity: null
	},
	computed: {
		owned() {
			return this.selectedEntity.isMainPlayer();
		},
		fleetName() {
			if(this.selectedEntity.isMainPlayer()) {
				return 'Your fleet';
			} else {
				return this.selectedEntity.owner.name + "'s fleet";
			}
		},
		fleetClass() {
			let classes = {};

			if(this.selectedEntity.isMainPlayer()) {
				classes['blue--text'] = true;
			} else if(this.selectedEntity.isEnemyPlayer()) {
				classes['red--text'] = true;
			}

			return classes;
		},
		maxFleetSize() {
			return Fleet.MAX_FLEET_SIZE;
		}
	}
};
</script>

<style scoped>
	.sector-ship {
		display: flex;
		flex-direction: row;
	}

	.fleet-card {
		margin-right: 1em;
	}
</style>