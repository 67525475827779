import Command from '../command';

class DockWithCommand extends Command {
	constructor(entity, dockWith) {
		super(entity);
		this.dockWith = dockWith;
	}

	update() {
		this.entity.dockWith(this.dockWith);
	}

	isDone() {
		return this.entity.dockedWith === this.dockWith;
	}
}

export default DockWithCommand;