import Entity from './entity';
import EntityFactory from './entity-factory';
import merge from 'merge';

class SolarSystem extends Entity {
	constructor() {
		super();
		
		this.name = null;
		this.planets = [];
	}

	loadPlanets(planetConfigs) {
		planetConfigs.forEach((origPlanetConfig) => {
			let planetConfig = merge({
				class: 'planet',
				sprite: 'planets/04'
			}, origPlanetConfig, {
				x: origPlanetConfig.x + this.x,
				y: origPlanetConfig.y + this.y
			});

			let planet = EntityFactory.getEntity(planetConfig);
			planet.solarSystem = this;
			this.planets.push(planet);
		});
	}

	save() {
		return {
			name: this.name,
			scale: this.scale,
			sprite: this.sprite?.texture?.key ?? this.sprite,
			x: this.x,
			y: this.y,
			planets: this.planets.map(planet => planet.save())
		};
	}

	get income() {
		return this.planets.reduce((total, planet) => {
			return planet.income + total;
		}, 0);
	}

	finishTurn() {
		this.planets.forEach((planet) => {
			planet.finishTurn();
		});
	}
}

export default SolarSystem;