<template>
	<v-card>
		<v-list dense>
			<v-subheader class="title">{{ building.name }}</v-subheader>

			<v-list-item>Level: {{ building.level }}</v-list-item>

			<v-list-item-group v-if="hasOrbitalPlatforms">
				<v-list-item>
					<v-list-item-icon>
						<v-icon>local_airport</v-icon>
					</v-list-item-icon>

					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-list-item-content v-on="on">Orbital defenses</v-list-item-content>
						</template>
						<div>
							<div class="tooltip-item" v-for="orbitalPlatform in building.currentLevel.orbitalPlatforms" :key="orbitalPlatform.name">
								<div>{{ orbitalPlatform.name }}:</div>
								<v-spacer class="tooltip-spacer"/>
								<div>{{ orbitalPlatform.quantity }} x {{ orbitalPlatform.attack }} dmg</div>
							</div>
						</div>
					</v-tooltip>
				</v-list-item>
			</v-list-item-group>

			<v-card-actions v-if="building.isUpgrading()">
				<v-btn color="red" @click="cancelUpgrade">
					Cancel upgrade: {{ building.upgradeTurnsLeft }} turns left
				</v-btn>
			</v-card-actions>
			<v-card-actions v-else-if="building.nextUpgrade()">
				<v-tooltip top :disabled="!building.nextUpgradeDescriptions()">
					<template v-slot:activator="{ on }">
						<v-btn color="primary" :disabled="!building.canUpgrade()" @click="upgradeBuilding" v-on="on">
							Upgrade: {{ building.nextUpgrade().cost }} ({{ building.nextUpgrade().turns }} turn)
						</v-btn>
					</template>
					<div>
						<div class="tooltip-item" v-for="description in building.nextUpgradeDescriptions()" :key="JSON.stringify(description)">
							<div>{{ description.type }}:</div>
							<v-spacer class="tooltip-spacer"/>
							<div>{{ description.value }}</div>
						</div>
					</div>
				</v-tooltip>
			</v-card-actions>
		</v-list>
	</v-card>
</template>

<script>
export default {
	props: {
		building: null
	},
	computed: {
		hasOrbitalPlatforms() {
			return this.isColony && this.building.currentLevel.orbitalPlatforms;
		},
		isColony() {
			return this.building.type === 'colony';
		}
	},
	methods: {
		cancelUpgrade() {
			this.building.cancelUpgrade();
		},
		upgradeBuilding() {
			this.building.upgrade();
		}
	}
};
</script>

<style scoped>
	.tooltip-item {
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
	}
	.tooltip-spacer {
		min-width: 0.5em;
	}
</style>