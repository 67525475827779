<template>
	<div class="overview-screen">
		<top-panel :player="player" v-if="player" />
		<div class="center-content">
			<component :is="dialog" v-if="dialog" ref="dialog" />
		</div>
		<bottom-panel :selected-entity="selectedEntity" :scene="scene" />

		<v-snackbar v-model="errorSnackbar" color="error">
			{{ errorMessage }}
	
			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="errorSnackbar = false">
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import TopPanel from './TopPanel';
import BottomPanel from './BottomPanel';

export default {
	name: 'OverviewScreen',
	data() {
		return {
			camera: null,
			player: null,
			scene: null,
			selectedEntity: null,
			dialog: null,
			errorSnackbar: false,
			errorMessage: null
		};
	},
	computed: {
		
	},
	watch: {
		errorMessage(value) {
			if(value) {
				this.errorSnackbar = true;
			}
		},
		errorSnackbar(value) {
			if(!value) {
				this.errorMessage = null;
			}
		}
	},
	components: {
		TopPanel,
		BottomPanel
	}
};
</script>

<style scoped>
	.overview-screen {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		pointer-events: none;
	}

	.overview-screen >>> .v-card .v-card {
		pointer-events: initial;
	}

	.center-content {
		flex-grow: 1;
	}
</style>