import Command from '../command';

class MergeIntoCommand extends Command {
	constructor(entity, mergeInto) {
		super(entity);
		this.mergeInto = mergeInto;
	}

	update() {
		this.entity.mergeInto(this.mergeInto);
	}

	isDone() {
		return !this.entity.shipsQuantity;
	}
}

export default MergeIntoCommand;