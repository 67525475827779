import { Scene } from 'phaser';
import TitleScreen from '@/client/components/title/TitleScreen';

export default class TitleScene extends Scene {
	constructor () {
		super({ key: 'TitleScene' });
	}

	preload () {
		this.game.hud.component = TitleScreen;
		this.game.hud.$nextTick(() => {
			this.game.hud.$refs.component.scene = this;
		});
	}
}
