import Phaser from 'phaser';
import SpriteFactory from './sprite-factory';

class SolarSystemSprite extends Phaser.GameObjects.Sprite {
	constructor(scene, entity) {
		super(scene, entity.x, entity.y, entity.sprite);
		this.scene = scene;
		this.entity = entity;
		this.entity.sprite = this;
		this.planets = [];
		this.orbitRings = [];
		
		this.init();
		this.initConfig(entity.config);
	}
	
	init() {
		this.scene.add.existing(this);
		this.scene.solarSystems.add(this);

		if(!this.nameLabel) {
			this.nameLabel = this.scene.add.dom(0, 0, 'div', 'font-size: 2.5rem;', '');
			this.nameLabel.node.className = 'phaser-galaxy-name-label yellow--text';
			this.nameLabel.setOrigin(0);
		}

		this.setInteractive();
		this.on('pointerup', (pointer) => {
			if(pointer.leftButtonReleased()) {
				this.scene.setSelectedSprite(this);
			}
		});
	}
	initConfig(entityConfig) {
		this.scaleX = this.scaleY = entityConfig.scale ?? 1;

		this.entity.planets.forEach((planet) => {
			this.orbitRings.push(this.scene.drawGraphics.strokeCircle(this.x, this.y, planet.x - this.x));
			this.planets.push(SpriteFactory.addSprite(this.scene, planet));
		});

		this.initNameLabel(entityConfig);
	}
	initNameLabel(entityConfig) {
		let bounds = this.getBounds();

		this.nameLabel.x = bounds.x;
		this.nameLabel.y = bounds.y + bounds.height;
		this.nameLabel.node.innerText = entityConfig.name;
		this.nameLabel.node.style.width = bounds.width + 'px';
	}

	getDefaultNameLabelFontSize() {
		return 2.5;
	}
	showSystemOverview() {
		this.scene.add.tween({
			targets: this.getSystemOverviewSprites(),
			alpha: { from: this.planets[0].alpha, to: 1 },
			duration: 300
		});

		this.nameLabel.node.style['font-size'] = this.getDefaultNameLabelFontSize() + 'rem';
	}
	hideSystemOverview() {
		let overviewSprites = this.getSystemOverviewSprites();
		this.scene.add.tween({
			targets: overviewSprites,
			alpha: { from: this.planets[0].alpha, to: 0 },
			duration: 300
		});

		this.nameLabel.node.style['font-size'] = (this.getDefaultNameLabelFontSize() * 2) + 'rem';

		overviewSprites.forEach((sprite) => {
			if(sprite.entity === this.scene.component.selectedEntity) {
				this.scene.component.selectedEntity = this.entity;
			}
		});
	}
	updateSystemSize(size) {
		this.nameLabel.node.style['font-size'] = (this.getDefaultNameLabelFontSize() * size) + 'rem';
	}
	getSystemOverviewSprites() {
		let planetLabels = this.planets.map((planet) => {
			return planet.nameLabel;
		});

		return this.planets.concat(this.orbitRings, planetLabels);
	}

	setSelected(selected) {
		this.selected = selected;

		if(selected) {
			this.setPipeline('outline');
		} else {
			this.resetPipeline();
		}
	}

	destroy() {
		if(this.scene) {
			this.scene.unselectIfSelected(this);

			this.planets.forEach((planet) => {
				planet.destroy();
			});
			super.destroy();
		}
	}
}

export default SolarSystemSprite;