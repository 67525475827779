import EntityFactory from './entity-factory';
import { EventEmitter } from 'eventemitter3';
import MainPlayer from '@/game/players/main-player';
import Computer from '@/game/players/computer';
import merge from 'merge';

class Universe extends EventEmitter {
	constructor() {
		super();

		this.mainPlayer = null;
		this.players = [];
		this.fleets = [];
		this.solarSystems = [];
		this.turn = 1;
		this.loading = false;
	}

	addPlayer(player) {
		this.players.push(player);
		EntityFactory.addPlayer(player);
		player.universe = this;
	}
	removePlayer(player) {
		let index = this.players.indexOf(player);
		if(index !== -1) {
			this.players.splice(index, 1);
		}
	}
	addFleet(fleet) {
		this.fleets.push(fleet);
		fleet.universe = this;

		this.emit('addFleet', fleet);
	}
	removeFleet(fleet) {
		let index = this.fleets.indexOf(fleet);
		if(index !== -1) {
			this.fleets.splice(index, 1);

			this.emit('removeFleet', fleet);
		}
	}

	addSolarSystem(solarSystem) {
		this.solarSystems.push(solarSystem);
		solarSystem.universe = this;
		solarSystem.planets.forEach((planet) => {
			if(planet.dockedFleet) {
				this.addFleet(planet.dockedFleet);
			}
		});
		
		this.emit('addSolarSystem', solarSystem);
	}
	removeSolarSystem(solarSystem) {
		let index = this.solarSystems.indexOf(solarSystem);
		if(index !== -1) {
			this.solarSystems.splice(index, 1);

			this.emit('removeSolarSystem', solarSystem);
		}
	}

	finishTurn() {
		this.players.forEach((player) => {
			player.finishTurn();
		});
		this.solarSystems.concat(this.fleets).forEach((entity) => {
			entity.finishTurn();
		});

		this.turn++;
	}

	load(config) {
		this.loading = true;
		while(this.players.length) {
			this.removePlayer(this.players[0]);
		}
		while(this.fleets.length) {
			this.removeFleet(this.fleets[0]);
		}
		while(this.solarSystems.length) {
			this.removeSolarSystem(this.solarSystems[0]);
		}

		config.players.forEach((playerConfig) => {
			let player;
			if(playerConfig.id === 'main-player') {
				player = this.mainPlayer = new MainPlayer();
			} else {
				player = new Computer(playerConfig.id, playerConfig.name);
			}

			player.load(playerConfig);
			this.addPlayer(player);
		});

		config.solarSystems.forEach((solarSystemCofig) => {
			this.addSolarSystem(EntityFactory.getEntity(merge({
				'class': 'solar-system'
			}, solarSystemCofig)));
		});

		config.fleets.forEach((fleetConfig) => {
			this.addFleet(EntityFactory.getEntity(fleetConfig));
		});

		this.turn = config.turn ?? 1;
		this.loading = false;
	}
	save() {
		return {
			turn: this.turn,
			players: this.players.map((player) => {
				return player.save();
			}),
			fleets: this.fleets.map((fleet) => {
				return fleet.save();
			}),
			solarSystems: this.solarSystems.map((solarSystem) => {
				return solarSystem.save();
			})
		};
	}
}

export default Universe;