import colony from './colony';
import shipyard from './shipyard';

let buildings = [
	colony,
	shipyard
];

buildings.forEach((building) => {
	building.level = 1;
	building.upgradeTurnsLeft = 0;
});

export default buildings;