class Command {
	constructor(entity) {
		this.entity = entity;
	}

	// eslint-disable-next-line	
	update(totalTime, elapsedTime) {}
	isDone() {
		return true;
	}
	isSucceeded() {
		return true;
	}
	isFailed() {
		return !this.isSucceeded();
	}
}

export default Command;