import Entity from './entity';

class Ship extends Entity {
	constructor() {
		super();

		this.class = 'ship';
		this.hull = 0;
		this.shields = 0;
		this.movement = 0;
		this.wingSize = 1;
		this.guns = 1;
		this.attack = 0;
		this.cost = 0;
		this.upkeep = 0;
		this.turns = 1;
		this.buildTurnsLeft = 0;
	}

	load(config) {
		super.load(config);

		if(config.quantity === undefined) {
			this.quantity = this.wingSize;
		}
	}
	saveFull() {
		return {
			class: this.class,
			hull: this.hull,
			shields: this.shields,
			movement: this.movement,
			wingSize: this.wingSize,
			guns: this.guns,
			attack: this.attack,
			cost: this.cost,
			upkeep: this.upkeep,
			turns: this.turns,
			buildTurnsLeft: this.buildTurnsLeft,
			sprite: this.sprite,
			scale: this.scale,
			type: this.type,
			quantity: this.quantity
		};
	}

	get wings() {
		return this.quantity / this.wingSize;
	}
}

export default Ship;