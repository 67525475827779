export default {
	type: 'colony',
	name: 'Colony',
	class: 'building',
	levels: [
		{
			level: 1,
			cost: 0
		},
		{
			level: 2,
			cost: 100,
			turns: 1,
			orbitalPlatforms: [
				{
					name: 'Light Batteries',
					attack: 4,
					hull: 20,
					shields: 0,
					quantity: 20
				}
			],
			upgradeDescriptions: [
				{
					type: 'Income',
					value: '+100'
				},
				{
					type: 'Defenses',
					value: '+20 Light Batteries'
				}
			]
		},
		{
			level: 3,
			cost: 200,
			turns: 2,
			orbitalPlatforms: [
				{
					name: 'Light Batteries',
					attack: 4,
					hull: 20,
					shields: 0,
					quantity: 40
				},
				{
					name: 'Medium Batteries',
					attack: 20,
					hull: 40,
					shields: 0,
					quantity: 10
				}
			],
			upgradeDescriptions: [
				{
					type: 'Income',
					value: '+100'
				},
				{
					type: 'Defenses',
					value: '+20 Light Batteries'
				},
				{
					type: 'Defenses',
					value: '+10 Medium Batteries'
				}
			]
		},
		{
			level: 4,
			cost: 400,
			turns: 2,
			orbitalPlatforms: [
				{
					name: 'Light Batteries',
					attack: 4,
					hull: 20,
					shields: 0,
					quantity: 90
				},
				{
					name: 'Medium Batteries',
					attack: 20,
					hull: 40,
					shields: 0,
					quantity: 20
				},
				{
					name: 'Heavy Batteries',
					attack: 200,
					hull: 80,
					shields: 0,
					quantity: 4
				}
			],
			upgradeDescriptions: [
				{
					type: 'Income',
					value: '+100'
				},
				{
					type: 'Defenses',
					value: '+50 Light Batteries'
				},
				{
					type: 'Defenses',
					value: '+10 Medium Batteries'
				},
				{
					type: 'Defenses',
					value: '+4 Heavy Batteries'
				}
			]
		}
	]
};