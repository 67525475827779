import Entity from './entity';

class Building extends Entity {
	constructor() {
		super();

		this.class = 'building';
		this.type = null;
		this.name = null;
		this.planet = null;
		this.level = 1;
		this.levels = [];
		this.upgradeTurnsLeft = 0;
	}

	get currentLevel() {
		return this.levels[this.level - 1];
	}

	saveFull() {
		return {
			type: this.type,
			name: this.name,
			class: this.class,
			level: this.level,
			levels: this.levels,
			upgradeTurnsLeft: this.upgradeTurnsLeft
		};
	}

	isUpgrading() {
		return this.upgradeTurnsLeft > 0;
	}
	canUpgrade() {
		let nextLevel = this.nextUpgrade();
		if(nextLevel) {
			let player = this.planet.owner;
			return player.money >= nextLevel.cost && !this.planet.isUpgradingBuilding();
		} else {
			return false;
		}
	}
	nextUpgrade() {
		if(!this.isMainPlayer()) {
			return null;
		}

		return this.levels.find((level) => {
			return level.level > this.level;
		});
	}
	nextUpgradeDescriptions() {
		let nextUpgrade = this.nextUpgrade();
		if(!nextUpgrade) {
			return null;
		}

		return nextUpgrade.upgradeDescriptions ?? null;
	}
	upgrade() {
		let nextLevel = this.nextUpgrade();
		let player = this.planet.owner;

		this.upgradeTurnsLeft = nextLevel.turns;
		player.money -= nextLevel.cost;
	}
	cancelUpgrade() {
		let nextLevel = this.nextUpgrade();
		let player = this.planet.owner;

		this.upgradeTurnsLeft = 0;
		player.money += nextLevel.cost;
	}

	finishTurn() {
		if(this.upgradeTurnsLeft > 0) {
			this.upgradeTurnsLeft--;

			if(this.upgradeTurnsLeft === 0) {
				let nextLevel = this.nextUpgrade();
				this.level = nextLevel.level;
			}
		}
	}

	get owner() {
		return this.planet.owner;
	}
	set owner(value) {}
}

export default Building;