<template>
	<div class="sector-selector">
		<v-card class="planet-card">
			<v-list dense>
				<v-subheader class="title">{{ selectedEntity.name }}</v-subheader>

				<template v-if="selectedEntity.isMainPlayer()">
					<v-list-item>Population: {{ selectedEntity.population }}</v-list-item>
					<v-list-item>Income: {{ selectedEntity.income }}</v-list-item>
				</template>
				<template v-else-if="selectedEntity.isEnemyPlayer()">
					<v-list-item><span class="red--text">{{ ownerName }}</span></v-list-item>
				</template>
				<template v-else>
					<v-list-item>{{ ownerName }}</v-list-item>
				</template>

				<v-card-actions v-if="selectedEntity.isBuildingShip()">
					<v-btn color="red" @click="cancelShipBuild">
						Cancel {{ selectedEntity.getShipBuilding().type }}: <br/>{{ selectedEntity.getShipBuilding().buildTurnsLeft }} turns left
					</v-btn>
				</v-card-actions>
				<v-card-actions v-else-if="selectedEntity.canBuildShips()">
					<v-menu>
						<template v-slot:activator="{ on }">
							<v-btn color="primary" :disabled="selectedEntity.isBuildingShip()" v-on="on">
								Build ships
							</v-btn>
						</template>

						<v-list>
							<v-list-item v-for="ship in selectedEntity.getShipsCanBuild()" :key="ship.id" @click="buildShip(ship)" :disabled="!selectedEntity.canBuildShip(ship)">
								x{{ ship.wingSize }} {{ ship.type }}:<v-spacer class="tooltip-spacer"/>{{ ship.cost }}
							</v-list-item>
						</v-list>
					</v-menu>
				</v-card-actions>
			</v-list>
		</v-card>

		<building v-for="building in buildings" :key="building.name" :building="building" />

		<v-card v-if="fleet">
			<v-list dense>
				<v-subheader class="title">Admiral {{ fleet.admiral.name }}</v-subheader>

				<v-card-actions class="card-actions">
					<v-btn color="primary" @click="selectFleet(fleet)">
						Select
					</v-btn>
				</v-card-actions>
			</v-list>
		</v-card>
	</div>
</template>

<script>
import Building from './cards/Building';

export default {
	props: {
		selectedEntity: null
	},
	computed: {
		buildings() {
			return this.selectedEntity.buildings;
		},
		fleet() {
			return this.selectedEntity.dockedFleet;
		},
		ownerName() {
			if(this.selectedEntity.isMainPlayer()) {
				return 'Your planet';
			} else if(this.selectedEntity.owner) {
				return this.selectedEntity.owner.name + "'s planet";
			} else {
				return 'Neutral planet';
			}
		}
	},
	methods: {
		canBuildShip(ship) {
			return this.selectedEntity.canBuildShip(ship);
		},
		buildShip(ship) {
			this.selectedEntity.addShipToBuildQueue(ship);
		},
		cancelShipBuild() {
			this.selectedEntity.cancelShipBuildingQueue();
		},
		selectFleet(fleet) {
			fleet.sprite.scene.setSelectedSprite(fleet.sprite);
		}
	},
	components: {
		Building
	}
};
</script>

<style scoped>
	.sector-selector {
		display: flex;
		flex-direction: row;
	}

	.planet-card {
		margin-right: 1em;
	}

	.card-actions {
		justify-content: center;
	}

	.tooltip-spacer {
		min-width: 0.5em;
	}
</style>