import Command from './command';

class CompositeCommand extends Command {
	constructor(entity, commands) {
		super(entity);

		this.commands = commands;
		this.currentCommand = 0;
		this.failed = false;
	}

	update(totalTime, elapsedTime) {
		this.commands[this.currentCommand].update(totalTime, elapsedTime);
	}
	isDone() {
		let currentCommand = this.commands[this.currentCommand];
		if(currentCommand) {
			if(currentCommand.isDone()) {
				if(currentCommand.isSucceeded()) {
					this.currentCommand++;
				} else {
					this.failed = true;
				}
			}
		}
		
		return this.currentCommand >= this.commands.length || this.failed;
	}
	isSucceeded() {
		return !this.failed;
	}
}

export default CompositeCommand;