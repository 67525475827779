import Player from './player';

class MainPlayer extends Player {
	constructor() {
		super();

		this.id = 'main-player';
	}
}

export default MainPlayer;