export default {
	players: [
		{
			id: 'main-player',
			money: 200
		},
		{
			id: 'cpu-1',
			name: 'CPU 1'
		}
	],
	solarSystems: [
		{
			name: 'Bajor',
			class: 'solar-system',
			x: 400,
			y: 400,
			sprite: 'stars/red',
			scale: 0.7,
			planets: [
				{
					x: 400,
					y: 0,
					scale: 0.2,
					name: 'Bajor 1',
					buildings: [
						{
							type: 'colony',
							level: 2
						},
						{
							type: 'shipyard',
							level: 2
						}
					],
					population: 100,
					owner: 'cpu-1',
					dockedFleet: {
						class: 'fleet',
						owner: 'cpu-1',
						ships: [
							{
								type: 'Light Fighter'
							}
						]
					}
				},
				{
					x: 700,
					y: 0,
					scale: 0.4,
					name: 'Bajor 2',
					buildings: [
						{
							type: 'colony',
							level: 1
						},
						{
							type: 'shipyard',
							level: 1
						}
					],
					population: 100,
					owner: 'main-player'
				},
				{
					x: 1000,
					y: 0,
					scale: 0.25,
					name: 'Bajor 3'
				},
				{
					x: 1300,
					y: 0,
					scale: 0.2,
					name: 'Bajor 4'
				},
				{
					x: 1600,
					y: 0,
					scale: 0.1,
					name: 'Bajor 5'
				}
			]
		},
		{
			name: 'Cardassia',
			class: 'solar-system',
			x: -3000,
			y: 1500,
			sprite: 'stars/red',
			scale: 0.7,
			planets: [
				{
					x: 400,
					y: 0,
					scale: 0.2,
					name: 'Cardassia 1'
				},
				{
					x: 700,
					y: 0,
					scale: 0.4,
					name: 'Cardassia 2'
				},
				{
					x: 1000,
					y: 0,
					scale: 0.25,
					name: 'Cardassia 3'
				}
			]
		}
	],
	fleets: [
		{
			class: 'fleet',
			x: 800,
			y: 600,
			owner: 'main-player',
			ships: [
				{
					type: 'Light Fighter',
					quantity: 40
				},
				{
					type: 'Light Frigate'
				},
				{
					type: 'Light Cruiser'
				}
			]
		},
		{
			class: 'fleet',
			x: 800,
			y: 800,
			owner: 'cpu-1',
			rotation: Math.PI / 4,
			ships: [
				{
					type: 'Light Fighter'
				},
				{
					type: 'Light Cruiser'
				}
			]
		}
	]
};