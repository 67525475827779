<template>
	<v-container fluid fill-height>
        <v-flex shrink align-self-center class="center">
			<router :routes="routes" :scene="scene" />
        </v-flex>
	</v-container>
</template>


<script>
import TitleMenu from './TitleMenu';
import AccountLogin from './AccountLogin';
import Router from '@/client/components/utils/Router';
import AccountCreation from './AccountCreation';
import AccountInfo from './AccountInfo';

export default {
	name: 'TitleScreen',
	data() {
		return {
			scene: null,
			routes: {
				default: TitleMenu,
				login: AccountLogin,
				createAccount: AccountCreation,
				account: AccountInfo
			}
		};
	},
	components: {
		Router
	}
};
</script>

<style scoped>
	.center {
        min-width: 40%;
        margin-left: auto;
        margin-right: auto;
    }
</style>