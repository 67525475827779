import { v4 as uuidv4 } from 'uuid';

class Player {
	constructor() {
		this.id = uuidv4();
		this.ownedPlanets = [];
		this.ownedFleets = [];
		this.money = 0;
	}
	
	finishTurn() {
		this.collectIncome();
	}

	collectIncome() {
		this.money += this.income - this.upkeep;
	}
	get income() {
		return this.ownedPlanets.reduce((total, planet) => {
			return planet.income + total;
		}, 0);
	}
	get upkeep() {
		return this.ownedFleets.reduce((total, fleet) => {
			return total + fleet.upkeep;
		}, 0);
	}

	load(config) {
		this.money = config.money;
	}
	save() {
		return {
			id: this.id,
			money: this.money ?? 0
		};
	}

	addPlanet(planet) {
		this.ownedPlanets.push(planet);
		planet.owner = this;
	}
	removePlanet(planet) {
		let index = this.ownedPlanets.indexOf(planet);
		if(index !== -1) {
			this.ownedPlanets.splice(index, 1);
		}

		if(planet.owner === this) {
			planet.owner = null;
		}
	}
}

export default Player;