import SolarSystem from './solar-system';
import Planet from './planet';
import Building from './building';
import Fleet from './fleet';
import Ship from './ship';
import merge from 'merge';

import buildings from '@/game/data/buildings';
import ships from '@/game/data/ships';

class EntityFactory {
	static classes = {
		'solar-system': SolarSystem,
		planet: Planet,
		building: Building,
		fleet: Fleet,
		ship: Ship
	};
	
	static isInit = false;
	static configs = {};
	static addConfig(config) {
		this.configs[config.type] = config;
	}
	
	// TODO: Stop with global players, should be passing in universe to getEntity or something
	static players = {};
	static addPlayer(player) {
		this.players[player.id] = player;
	}

	static init() {
		if(this.isInit) {
			return;
		}

		buildings.forEach((buildingConfig) => {
			this.addConfig(buildingConfig);
		});
		ships.forEach((shipConfig) => {
			this.addConfig(shipConfig);
		});

		this.isInit = true;
	}
	static clear() {
		this.players = {};
	}

	static getEntity(config) {
		let baseConfig = null;
		if(config.type) {
			baseConfig = this.configs[config.type];
			config = merge({}, baseConfig, config);
		}

		if(!config.class || !this.classes[config.class]) {
			console.error('No config for class: ', config);
		}
		let entity = new this.classes[config.class]();
		entity.load(config);
		entity.baseConfig = baseConfig;

		return entity;
	}
}

export default EntityFactory;