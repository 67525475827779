import localForage from 'localforage';

const SAVE_GAME_NAME = 'galaxy-total-war-main-save';

class SaveService {
	constructor() {
		this.universe = null;
		this.save = null;
		this.firebase = null;
		this.user = null;

		localForage.getItem(SAVE_GAME_NAME).then((config) => {
			if(config) {
				this.save = config;
			}
		});
	}

	get userDoc() {
		let db = this.firebase.firestore();
		return db.collection('users').doc(this.user.uid);
	}

	async saveGame() {
		let config = this.universe.save();
		this.save = config;
		await localForage.setItem(SAVE_GAME_NAME, config);

		// Do not wait for this since it is fine if db save is a background operation
		this.userDoc.collection('saves').doc('main-game').set(config);
	}

	async setUser(user) {
		this.user = user;

		if(user) {
			let result = await this.userDoc.collection('saves').doc('main-game').get();
			if(result.exists) {
				let config = result.data();
				this.save = config;

				// Update existing game save in storage
				await localForage.setItem(SAVE_GAME_NAME, config);
			}
		}
	}
}

export default SaveService;