export default {
	type: 'Light Cruiser',
	class: 'ship',
	sprite: 'ships/cruiser',
	scale: 0.4,
	hull: 200,
	guns: 1,
	attack: 50,
	shields: 2,
	movement: 300,
	wingSize: 1,
	cost: 400,
	upkeep: 20,
	turns: 2,
	size: 20
};