<template>
	<v-dialog v-model="visible" content-class="attack-fleet-dialog" >
		<v-card>
			<v-card-title class="headline">Attack</v-card-title>

			<div class="attack-fleet-dialog-fleet-splitter">
				<v-list dense v-if="myFleet">
					<v-subheader class="title">Your fleet</v-subheader>

					<v-list-item v-for="ship in myFleet.ships" :key="ship.id">x{{ Math.ceil(ship.quantity) }} {{ ship.type }}</v-list-item>
				</v-list>

				<div style="min-width: 2em" />

				<v-list dense v-if="enemyFleet">
					<v-subheader class="title">Enemy fleet</v-subheader>

					<v-list-item v-for="ship in enemyFleet.ships" :key="ship.id">x{{ Math.ceil(ship.quantity) }} {{ ship.type }}</v-list-item>
				</v-list>
			</div>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="orange darken-1" text @click="automateAttack">
					Automate
				</v-btn>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn color="green darken-1" text v-on="on">
							Lead Attack
						</v-btn>
					</template>
					<span>Coming soon...</span>
				</v-tooltip>
				
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import FleetBattleResultsDialog from './FleetBattleResultsDialog';
import { calculateFleetBattle } from '@/game/utils/battles';

export default {
	data() {
		return {
			visible: true,
			myFleet: null,
			enemyFleet: null,
			scene: null
		};
	},
	methods: {
		automateAttack() {
			let results = calculateFleetBattle(this.myFleet, this.enemyFleet);
			
			if(this.myFleet.isDestroyed()) {
				this.myFleet.explode();
			}
			if(this.enemyFleet.isDestroyed()) {
				this.enemyFleet.explode();
			}
			
			this.scene.setDialog(FleetBattleResultsDialog, {
				myFleet: this.myFleet,
				enemyFleet: this.enemyFleet,
				results: results
			});
		}
	}
};
</script>

<style>
	.attack-fleet-dialog {
		display: flex;
		width: initial;
	}

	.attack-fleet-dialog-fleet-splitter {
		display: flex;
		flex-direction: row;
	}
</style>