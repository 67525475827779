import Player from './player';

class Computer extends Player {
	constructor(id, name) {
		super();

		this.id = id;
		this.name = name;
	}
}

export default Computer;