<template>
	<div class="turn-panel">
		<v-card>
			<v-list dense>
				<v-subheader class="title">Turn {{ scene.universe.turn }}</v-subheader>

				<v-card-actions>
					<v-btn color="primary" @click="endTurn">End Turn</v-btn>
				</v-card-actions>
			</v-list>
		</v-card>
	</div>
</template>

<script>
export default {
	props: {
		scene: null
	},
	methods: {
		endTurn() {
			this.scene.endTurn();
		}
	}
};
</script>

<style scoped>
	.turn-panel {
		align-self: end;
	}
</style>