export default {
	type: 'shipyard',
	name: 'Shipyard',
	class: 'building',
	levels: [
		{
			level: 1,
			cost: 0,
			ships: ['Light Fighter'],
			upgradeDescriptions: [
				{
					type: 'Unlock',
					value: 'Light Fighter'
				}
			]
		},
		{
			level: 2,
			cost: 100,
			turns: 1,
			ships: ['Light Fighter', 'Light Frigate'],
			upgradeDescriptions: [
				{
					type: 'Unlock',
					value: 'Light Frigate'
				}
			]
		},
		{
			level: 3,
			cost: 200,
			turns: 2,
			ships: ['Light Fighter', 'Light Frigate', 'Light Cruiser'],
			upgradeDescriptions: [
				{
					type: 'Unlock',
					value: 'Light Cruiser'
				}
			]
		}
	]
};