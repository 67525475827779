export default {
	type: 'Light Fighter',
	class: 'ship',
	sprite: 'ships/fighter',
	scale: 0.5,
	hull: 10,
	guns: 1,
	attack: 4,
	shields: 0,
	movement: 300,
	wingSize: 20,
	cost: 100,
	upkeep: 5,
	turns: 1,
	size: 1
};