import calculateRoundOfBattle from './battle-round';
import simplifyShips from './simplify-ships';

export default function calculatePlanetBattle(attackingFleet, defendingPlanet) {
	let attackingShips = attackingFleet.ships;
	let defenses = defendingPlanet.defenses;
	let lostAttackingShips = simplifyShips(attackingShips);
	let lostDefenses = simplifyShips(defenses);

	while(attackingFleet.shipsQuantity > 0 && defendingPlanet.defensesQuantity > 0) {
		calculateRoundOfBattle(attackingShips, defenses);
	}

	lostAttackingShips.forEach((ship, index) => {
		ship.quantity = ship.quantity - attackingShips[index].quantity;
	});
	lostDefenses.forEach((defense, index) => {
		defense.quantity = defense.quantity - defenses[index].quantity;
	});

	attackingFleet.ships = attackingFleet.ships.filter((ship) => {
		return ship.quantity > 0;
	});
	if(defendingPlanet.dockedFleet) {
		defendingPlanet.dockedFleet.ships = defendingPlanet.dockedFleet.ships.filter((ship) => {
			return ship.quantity > 0;
		});
	}

	return {
		attackWon: attackingFleet.shipsQuantity > 0,
		lostAttackingShips,
		lostDefenses
	};
}