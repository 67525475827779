import Phaser from 'phaser';
import SpriteFactory from './sprite-factory';

class PlanetSprite extends Phaser.GameObjects.Sprite {
	constructor(scene, entity) {
		super(scene, entity.x, entity.y, entity.sprite);
		this.scene = scene;
		this.entity = entity;
		
		this.init();
		this.initConfig(entity.config);
	}
	
	init() {
		this.scene.add.existing(this);
		this.scene.planets.add(this);

		if(!this.nameLabel) {
			this.nameLabel = this.scene.add.dom(0, 0, 'div', 'font-size: 1.2rem;', '');
			this.nameLabel.node.className = ' phaser-galaxy-name-label yellow--text';
			this.nameLabel.setOrigin(0);
		}

		this.setInteractive();
		this.on('pointerup', (pointer) => {
			if(pointer.leftButtonReleased()) {
				this.scene.setSelectedSprite(this);
			}
		});
	}
	initConfig(entityConfig) {
		this.scaleX = this.scaleY = entityConfig.scale ?? 1;
		this.initNameLabel(entityConfig);

		if(this.entity.dockedFleet) {
			SpriteFactory.addSprite(this.scene, this.entity.dockedFleet);
		}
	}

	initNameLabel(entityConfig) {
		let bounds = this.getBounds();

		this.nameLabel.x = bounds.x;
		this.nameLabel.y = bounds.y + bounds.height * 1.05;
		this.nameLabel.node.innerText = entityConfig.name;
		this.nameLabel.node.style.width = bounds.width + 'px';
	}

	setSelected(selected) {
		this.selected = selected;

		if(selected) {
			this.setPipeline('outline');
		} else {
			this.resetPipeline();
		}
	}

	destroy() {
		if(this.scene) {
			this.scene.unselectIfSelected(this);
			super.destroy();
		}
	}
}

export default PlanetSprite;