import { v4 as uuidv4 } from 'uuid';

export default function simplifyShips(ships) {
	return ships.map((ship) => {
		return simplifyShip(ship);
	});
}
function simplifyShip(ship) {
	return {
		id: ship.id ?? uuidv4(),
		type: ship.type ?? ship.name,
		quantity: ship.quantity,
		guns: ship.guns ?? 1,
		attack: ship.attack,
		hull: ship.hull,
		shields: ship.shields
	};
}