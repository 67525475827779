import lightFighter from './light-fighter';
import lightFrigate from './light-frigate';
import lightCruiser from './light-cruiser';

let ships = [
	lightFighter,
	lightFrigate,
	lightCruiser
];

// Add some default data we don't want to manually maintain in each def
ships.forEach((ship) => {
	ship.buildTurnsLeft = 0;
	ship.quantity = ship.wingSize;
});

export default ships;