<template>
	<v-card class="top-panel">
		<player-summary :player="player" />
		<div class="right-content" />
	</v-card>
</template>

<script>
import PlayerSummary from './PlayerSummary';

export default {
	props: {
		player: null
	},
	components: {
		PlayerSummary
	}
};
</script>

<style scoped>
	.top-panel {
		background-color: transparent;

		display: flex;
		flex-direction: row;
	}

	.right-content {
		flex-grow: 1;
	}
</style>