import Phaser from 'phaser';

// Grabbed from https://phaser.discourse.group/t/sprite-outline-via-shader-showcase-looking-for-improvements/2375
// Possible alt: https://github.com/rexrainbow/phaser3-rex-notes/blob/master/plugins/shaders/outline/outline-frag.js
class OutlinePipeline extends Phaser.Renderer.WebGL.Pipelines.TextureTintPipeline {
	constructor(game) {
		let config = {
			game,
			renderer: game.renderer,
			fragShader: `
				precision mediump float;
				uniform sampler2D uMainSampler;
				varying vec2 outTexCoord;
				void main(void) {
					vec4 color = texture2D(uMainSampler, outTexCoord);
					vec4 colorU = texture2D(uMainSampler, vec2(outTexCoord.x, outTexCoord.y - 0.01));
					vec4 colorD = texture2D(uMainSampler, vec2(outTexCoord.x, outTexCoord.y + 0.01));
					vec4 colorL = texture2D(uMainSampler, vec2(outTexCoord.x + 0.01, outTexCoord.y));
					vec4 colorR = texture2D(uMainSampler, vec2(outTexCoord.x - 0.01, outTexCoord.y));
					
					gl_FragColor = color;
					
					if (color.a == 0.0 && (colorU.a != 0.0 || colorD.a != 0.0 || colorL.a != 0.0 || colorR.a != 0.0)  ) {
						gl_FragColor = vec4(1.0, 0.0, 0.0, .2);
					}
				}`
		};
		super(config);
	}
}

export default OutlinePipeline;