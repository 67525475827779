import Entity from './entity';
import Planet from './planet';
import MoveToCommand from '@/game/commands/fleets/move-to';
import DockWithCommand from '@/game/commands/fleets/dock-with';
import MergeIntoCommand from '@/game/commands/fleets/merge-into.js';
import CompositeCommand from '@/game/commands/composite-command';
import CallbackCommand from '@/game/commands/callback-command';
import EntityFactory from './entity-factory';
import Fakerator from 'fakerator';

const MAX_FLEET_SIZE = 100;
const fakerator = Fakerator();

export default class Fleet extends Entity {
	constructor() {
		super();

		this.class = 'fleet';
		this.command = null;
		this.movementLeft = 0;
		this.rotation = 0;
		this.dockedWith = null;
		this.ships = [];
		this.name = 'Fleet';
		this.admiral = null;
	}

	get movement() {
		return Math.min(...this.ships.map(ship => ship.movement));
	}
	get shipsQuantity() {
		return this.ships.reduce((total, ship) => {
			return total + Math.ceil(ship.quantity);
		}, 0);
	}
	get shipsSize() {
		return this.ships.reduce((total, ship) => {
			return total + Math.ceil(ship.size * ship.quantity);
		}, 0);
	}
	static get MAX_FLEET_SIZE() {
		return MAX_FLEET_SIZE;
	}
	get upkeep() {
		return Math.round(this.ships.reduce((total, ship) => {
			return total + ship.upkeep * ship.wings;
		}, 0));
	}

	isDestroyed() {
		return this.shipsQuantity <= 0;
	}

	getFlagShip() {
		return this.ships.reduce((maxShip, ship) => {
			if(ship.hull > maxShip.hull) {
				return ship;
			} else {
				return maxShip;
			}
		}, this.ships[0]);
	}

	load(config) {
		super.load(config);

		if(config.movementLeft === undefined) {
			this.movementLeft = this.movement;
		}

		if(this.admiral === null) {
			this.admiral = {
				name: fakerator.names.lastName()
			};
		}
	}
	loadOwner(owner) {
		super.loadOwner(owner);

		if(this.owner) {
			this.owner.ownedFleets.push(this);
		}
	}
	loadShips(shipConfigs) {
		shipConfigs.forEach((shipConfig) => {
			this.ships.push(EntityFactory.getEntity(shipConfig));
		});
	}

	saveFull() {
		let config = {
			class: this.class,
			command: this.command,
			owner: this.owner?.id,
			movementLeft: this.movementLeft,
			rotation: this.rotation,
			// dockedWith: this.dockWith,
			ships: this.ships.map(ship => ship.save()),
			name: this.name,
			admiral: this.admiral,
			x: this.x,
			y: this.y,
			scale: this.scale
		};

		if(config.rotation === 0) {
			delete config.rotation;
		}
		if(!config.command) {
			delete config.command;
		}
		if(config.movementLeft == this.movement) {
			delete config.movementLeft;
		}

		return config;
	}

	updateDisplay(totalTime, elapsedTime) {
		if(this.command) {
			this.command.update(totalTime, elapsedTime);

			if(this.command && this.command.isDone()) {
				this.setCommand(null);
			}
		}
	}

	dockWith(dockWith) {
		this.dockedWith = dockWith;
		this.visible = false;

		dockWith.dockFleet(this);
	}
	unDock() {
		this.dockedWith.undockFleet(this);

		this.dockedWith = null;
		this.visible = true;
	}

	mergeInto(otherFleet) {
		this.ships.forEach((ship) => {
			let otherShipMatch = otherFleet.ships.find(otherShip => otherShip.type === ship.type);
			if(otherShipMatch) {
				otherShipMatch.quantity += ship.quantity;
			} else {
				otherFleet.ships.push(ship);
			}
		});

		// Make sure still in correct order for displaying
		otherFleet.ships.sort((a, b) => a.size - b.size);
		otherFleet.movementLeft = Math.min(otherFleet.movementLeft, this.movementLeft);

		this.ships.splice(0, this.ships.length);
		this.destroy();
	}

	setDestination(clickedOnEntities, x, y, onComplete) {
		if(this.dockedWith) {
			this.unDock();
		}

		let status = {
			errorMessage: null
		};
		let moveToCommand;
		if(clickedOnEntities.length) {
			let destinationEntity = clickedOnEntities[0];
			
			moveToCommand = new MoveToCommand(this, destinationEntity.x, destinationEntity.y);
			moveToCommand.getWithin = 40;
		} else {
			moveToCommand = new MoveToCommand(this, x, y);
		}

		let planet = clickedOnEntities.find(entity => entity instanceof Planet);
		let myFleet = clickedOnEntities.find(entity => entity instanceof Fleet && entity.isMainPlayer() && entity !== this);
		if(planet && !planet.isEnemyPlayer()) {
			this.setCommand(new CompositeCommand(this, [moveToCommand, new DockWithCommand(this, planet)]));
		} else if(myFleet) {
			if(myFleet.shipsSize + this.shipsSize <= MAX_FLEET_SIZE) {
				this.setCommand(new CompositeCommand(this, [moveToCommand, new MergeIntoCommand(this, myFleet)]));
			} else {
				status.errorMessage = `Can't merge fleets together.  It would be too big!`;
			}
		} else if(onComplete) {
			this.setCommand(new CompositeCommand(this, [moveToCommand, new CallbackCommand(this, onComplete)]));
		} else {
			this.setCommand(moveToCommand);
		}

		return status;
	}

	setCommand(command) {
		// Don't try to start running already done command!
		if(command && command.isDone()) {
			command = null;
		}

		this.command = command;
	}

	finishTurn() {
		this.movementLeft = this.movement;
	}

	explode() {
		if(this.sprite) {
			this.sprite.explode();
		}

		this.destroy();
	}
	destroy() {
		if(this.dockedWith) {
			this.dockedWith.dockedFleet = null;
		}

		let index = this.owner.ownedFleets.indexOf(this);
		if(index !== -1) {
			this.owner.ownedFleets.splice(index, 1);
		}

		this.universe.removeFleet(this);
	}
}