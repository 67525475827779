import isPlainObject from 'is-plain-object';
import { v4 as uuidv4 } from 'uuid';
import { EventEmitter } from 'eventemitter3';
import EntityFactory from './entity-factory';

class Entity extends EventEmitter {
	constructor() {
		super();
		this.id = null;
		this.x = 0;
		this.y = 0;
		this.name = null;
		this.owner = null;
		this.universe = null;
	}

	load(config) {
		let delayedLoads = [];		
		for(let name in config) {
			let titleCaseName = name[0].toUpperCase() + name.slice(1);
			let loadName = `load${titleCaseName}`;
			if(this[loadName]) {
				delayedLoads.push([loadName, config[name]]);
			} else if(isPlainObject(config[name])) {
				if(this[name] && this[name].load) {
					this[name].load(config[name]);
				} else {
					this[name] = config[name];
				}
			} else {
				this[name] = config[name];
			}
		}

		// Need to load simple data before we load complicated data like planets that might depend on position of solar system
		// Firebase saving can change order of properties so we can't rely on it
		delayedLoads.forEach((load) => {
			this[load[0]](load[1]);
		});

		if(!this.id) {
			this.id = uuidv4();
		}

		this.config = config;
	}
	loadOwner(owner) {
		this.owner = EntityFactory.players[owner];
	}

	save() {
		let fullConfig = this.saveFull();

		if(this.baseConfig) {
			for(let prop in this.baseConfig) {
				if(prop !== 'type' && fullConfig[prop] == this.baseConfig[prop]) {
					delete fullConfig[prop];
				}
			}
		}

		for(let prop in fullConfig) {
			if(fullConfig[prop] === undefined) {
				delete fullConfig[prop];
			}
		}

		return fullConfig;
	}

	isMainPlayer() {
		return this.owner && this.owner.id === 'main-player';
	}
	isEnemyPlayer() {
		return this.owner && !this.isMainPlayer();
	}
	isAllyPlayer() {
		return false;
	}
}

export default Entity;