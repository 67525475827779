<template>
	<v-card align-center class="account-menu">
		<v-card-text class="headline text-xs-center" >
			Account Information
		</v-card-text>

		<v-card-text>Id: {{ user.uid }}</v-card-text>
		<v-card-text>Email: {{ user.email }}</v-card-text>

		<v-card-text v-if="isLinkedGoogle">Linked to Google: {{ isLinkedGoogle.displayName }} ({{ isLinkedGoogle.email }})</v-card-text>
		<v-btn @click="linkToGoogle" :loading="loading" v-else>Link to Google</v-btn>

		<v-card-actions>
			<v-btn @click="back">Back</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="red" dark @click="logout">Logout</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	props: {
		router: null
	},
	data() {
		return {
			loading: false,
			google: new this.firebase.auth.GoogleAuthProvider()
		};
	},
	computed: {
		user() {
			return this.firebase.auth().currentUser;
		},
		isLinkedGoogle() {
			return this.user.providerData.filter((provider) => {
				return provider.providerId === this.google.providerId;
			})[0];
		}
	},
	methods: {
		back() {
			this.router.back();
		},
		linkToGoogle() {
			this.loading = true;
			this.user.linkWithPopup(this.google).then(function() {
				
			}).catch((error) => {
				alert(error);
			}).finally(() => {
				this.loading = false;
			});
		},
		logout() {
			this.firebase.auth().signOut().then(() => {
				this.router.back();
			});
		}
	}
};
</script>

<style scoped>
	.account-menu {
		pointer-events: initial;
	}
</style>