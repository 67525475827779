<template>
	<v-card align-center class="login-menu">
		<v-progress-circular class="loading" indeterminate color="primary" v-if="loading" />
		<template v-else>
			<v-card-text class="headline text-xs-center" >
				Login to account
			</v-card-text>

			<v-container grid-list-md>
				<v-layout row wrap>
					<v-form ref="form" v-model="valid" lazy-validation class="form" @keyup.native.enter="submit">
						<v-text-field prepend-icon="person" name="email" label="Email" type="email" v-model="email" :rules="emailRules" required />
						<v-text-field prepend-icon="lock" name="password" label="Password" id="password" type="password" required v-model="password" :rules="passwordRules" />
					</v-form>
				</v-layout>
			</v-container>

			<v-card-text>
				Or <v-btn style="margin-left: 0.5em" color="primary" @click="signInWithGoogle">sign in with Google</v-btn>
			</v-card-text>

			<v-card-actions>
				<v-btn  @click="back">Back</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="green" dark @click="createAccount">Create Account</v-btn>
				<v-btn color="primary" :disabled="!valid" @click="submit">Login</v-btn>
			</v-card-actions>
		</template>
	</v-card>
</template>

<script>
export default {
	props: {
		router: null
	},
	data() {
		return {
			loading: false,
			valid: false,
			email: '',
			password: '',
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+/.test(v) || 'E-mail must be valid'
			],
			passwordRules: [
				v => !!v || 'Password is required',
				v => v.length >= 6 || 'Password must be greater than 6 characters'
			],
			google: new this.firebase.auth.GoogleAuthProvider()
		};
	},
	methods: {
		back() {
			this.router.back();
		},
		createAccount() {
			this.router.goTo('createAccount');
		},
		submit() {
			if(!this.$refs.form.validate()) {
				return;
			}

			this.loading = true;
			this.firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
				this.router.back();
				this.router.goTo('account');
			}).catch((error) => {
				alert(error);
			}).finally(() => {
				this.loading = false;
			});
		},
		signInWithGoogle() {
			this.loading = true;
			this.firebase.auth().signInWithPopup(this.google).then(() => {
				this.router.back();
				this.router.goTo('account');
			}).catch((error) => {
				alert(error);
			}).finally(() => {
				this.loading = false;
			});
		}
	}
};
</script>

<style scoped>
	.login-menu {
		pointer-events: initial;
	}

	.form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.loading {
		min-height: 10em;
	}
</style>