import simplifyShips from './simplify-ships';

export default function calculateRoundOfBattle(attackingShips, defendingShips) {
	let startingAttackingShips = simplifyShips(attackingShips);
	let startingDefendingShips = simplifyShips(defendingShips);

	calculateShipsAttacking(startingAttackingShips, defendingShips);
	calculateShipsAttacking(startingDefendingShips, attackingShips);
}
function calculateShipsAttacking(attackingShips, defendingShips) {
	attackingShips.forEach((attackingShip) => {
		for(let i = 0; i < attackingShip.quantity; i++) {
			for(let j = 0; j < attackingShip.guns; j++) {
				let defendingShip = defendingShips[Math.floor(Math.random() * defendingShips.length)];
				let attackDamage = Math.max(0, attackingShip.attack - defendingShip.shields);
				if(attackDamage > 0) {
					defendingShip.quantity = Math.max(0, defendingShip.quantity - attackDamage / defendingShip.hull);
				}
			}
		}
	});
}