import { Scene } from 'phaser';
import LoadingScreen from '@/client/components/boot/LoadingScreen';

import EntityFactory from '@/game/entities/entity-factory';
import SpriteFactory from '@/client/phaser/sprites/sprite-factory';

import redSun from '@/game/assets/stars/SunRed.png';
import planet04 from '@/game/assets/planets/planet_04.png';

import fighter from '@/game/assets/ships/fighter.png';
import cruiser from '@/game/assets/ships/cruiser.png';

import explode from '@/game/assets/explode.png';

export default class BootScene extends Scene {
	constructor () {
		super({ key: 'BootScene' });
	}

	preload () {
		this.game.hud.component = LoadingScreen;
		this.load.on('progress', (value) => {
			this.onProgress(value);
		});

		this.load.image('stars/red', redSun);
		this.load.image('planets/04', planet04);

		this.load.image('ships/fighter', fighter);
		this.load.image('ships/cruiser', cruiser);
		this.load.spritesheet('explode-sheet', explode, {
			frameWidth: 128,
			frameHeight: 128
		});
		EntityFactory.init();
	}

	create () {
		this.scene.start('TitleScene');

		SpriteFactory.addConfig({
			type: 'entity'
		});
	}

	onProgress(value) {
		this.game.hud.$nextTick(() => {
			this.game.hud.$refs.component.loading = value * 100;
		});
	}
}
