import EntityFactory from '@/game/entities/entity-factory';
import SolarSystemSprite from './solar-system';
import PlanetSprite from './planet';
import FleetSprite from './fleet';

class SpriteFactory {
	static addConfig(config) {
		EntityFactory.addConfig(config);
	}

	static addSprite(scene, entity) {
		if(entity.class === 'solar-system') {
			return new SolarSystemSprite(scene, entity);
		} else if(entity.class === 'planet') {
			return new PlanetSprite(scene, entity);
		} else if(entity.class === 'fleet') {
			return new FleetSprite(scene, entity);
		} else {
			throw 'No matching class for ' + entity.class;
		}
	}
}

export default SpriteFactory;