<template>
	<v-card align-center class="title-menu">
        <v-card-text class="headline text-xs-center" >
            Main Menu
        </v-card-text>

        <v-container grid-list-md>
            <v-layout row wrap>
                <v-flex xs12 v-if="continueConfig">
                    <v-btn @click="load">Continue</v-btn>
                </v-flex>

                <v-flex xs12>
                    <v-btn @click="newGame">New Game</v-btn>
                </v-flex>

				<v-flex xs12 v-if="scene">
					<v-btn @click="viewAccount" v-if="scene.game.user">Account Info</v-btn>
                    <v-btn @click="login" v-else>Login Account</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>


<script>
export default {
	props: {
		scene: null,
		router: null
	},
	name: 'TitleMenu',
	computed: {
		continueConfig() {
			return this.scene && this.scene.game.saveService.save;
		}
	},
	methods: {
		load() {
			let scene = this.scene.scene.manager.getScene('GalaxyScene');
			scene.config = this.continueConfig;
			this.scene.scene.start(scene);
		},
		newGame() {
			this.scene.scene.start('GalaxyScene');
		},
		login() {
			this.router.goTo('login');
		},
		viewAccount() {
			this.router.goTo('account');
		}
	}
};
</script>

<style scoped>
	.title-menu {
		pointer-events: initial;
	}
</style>