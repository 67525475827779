<template>
	<v-dialog v-model="visible" content-class="attack-fleet-dialog" >
		<v-card>
			<v-card-title class="headline">Battle Results</v-card-title>

			<div class="attack-fleet-dialog-fleet-splitter">
				<v-list dense v-if="myFleet">
					<v-subheader class="title">Your fleet <template v-if="!results.attackWon"> (destroyed)</template></v-subheader>

					<v-list-item v-for="ship in results.lostAttackingShips" :key="ship.id">Lost x{{ Math.ceil(ship.quantity) }} {{ ship.type }}</v-list-item>
				</v-list>

				<div style="min-width: 2em" />

				<v-list dense v-if="enemyPlanet">
					<v-subheader class="title">Enemy fleet <template v-if="results.attackWon">(destroyed)</template></v-subheader>

					<v-list-item v-for="defense in results.lostDefenses" :key="defense.id">Destroyed x{{ Math.ceil(defense.quantity) }} {{ defense.type }}</v-list-item>
				</v-list>
			</div>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="green darken-1" text @click="visible = false">
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			visible: true,
			myFleet: null,
			enemyPlanet: null,
			results: null
		};
	}
};
</script>

<style scoped>
	
</style>