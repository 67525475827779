<template>
	<v-dialog v-model="visible" content-class="attack-fleet-dialog">
		<v-card align-center v-on:mouseup.stop>
			<v-card-title class="headline centered">Game Paused</v-card-title>

			<v-container grid-list-md>
            <v-layout row wrap>
				<v-flex xs12 v-if="continueConfig">
                    <v-btn @click="load">Load Game</v-btn>
                </v-flex>
                <v-flex xs12>
                    <v-btn @click="save">Save Game</v-btn>
                </v-flex>

                <v-flex xs12>
                    <v-btn @click="visible = false">Resume Game</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			scene: null,
			visible: true,
			universe: null
		};
	},
	computed: {
		continueConfig() {
			return this.scene && this.scene.game.saveService.save;
		}
	},
	methods: {
		load() {
			this.universe.load(this.continueConfig);
			this.visible = false;
		},
		async save() {
			await this.scene.game.saveService.saveGame();
			this.visible = false;
		}
	}
};
</script>

<style scoped>
	.centered {
		justify-content: center;
	}
</style>